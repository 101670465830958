<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ productOption.id === undefined ? $t('admin.sub.productOptions.form.create.title') : $t('admin.sub.productOptions.form.edit.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ productOption.id === undefined ? $t('admin.sub.productOptions.form.create.subtitle') : $t('admin.sub.productOptions.form.edit.subtitle', {reference: productOption.reference}) }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && submitProduct()">
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="group" :items="productGroups" item-value="id" item-text="name" required chips dense
                          :label="$t('admin.sub.productOptions.form.input.group.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="taxRates" :items="productTaxRates" item-value="rate" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.productOptions.form.input.taxes.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="reference" :counter="referenceMaxLength" required dense :rules="referenceRules"
                              :label="$t('admin.sub.productOptions.form.input.reference.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="name" :counter="nameMaxLength" required dense :rules="nameRules"
                              :label="$t('admin.sub.productOptions.form.input.name.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea v-model="description" :counter="descriptionMaxLength" dense :rules="descriptionRules" auto-grow rows="1" row-height="15"
                            :label="$t('admin.sub.productOptions.form.input.description.label')" :clearable="true"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="tags" :items="productTags" item-value="id" item-text="name" required multiple chips dense
                          :label="$t('admin.sub.products.form.input.tags.label')" :clearable="true"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" />
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="defaultQuantity" type="number" required dense :label="$t('admin.sub.productOptions.form.input.defaultQuantity.label')" min="0" max="10" />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="minQuantity" type="number" required dense :label="$t('admin.sub.productOptions.form.input.minQuantity.label')" min="0" max="10" />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="maxQuantity" type="number" required dense :label="$t('admin.sub.productOptions.form.input.maxQuantity.label')" min="0" max="10" />
              </v-col>
            </v-row>
            <edit-product-price-form :product="productOption" :tax-rates="taxRates" @update="updatePrices" />
            <edit-product-image-form :product="productOption" @update="updateImages" />
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('admin.sub.productOptions.form.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && submitProduct()">
              {{ $t('admin.sub.productOptions.form.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import EditProductPriceForm from '@/views/forms/product/EditProductPriceForm';
  import EditProductImageForm from '@/views/forms/product/EditProductImageForm';

  export default {
    name: 'EditProductForm',
    components: { EditProductPriceForm, EditProductImageForm },
    props: {
      productOption: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dialog: false,
        group: null,
        productGroups: [],
        reference: '',
        referenceMinLength: 1,
        referenceMaxLength: 50,
        referenceRules: [
          v => !!v || this.$t('admin.sub.productOptions.form.input.reference.rule.required'),
          v => (v && v.length >= this.referenceMinLength) || this.$t('admin.sub.productOptions.form.input.reference.rule.minLength', { count: this.referenceMinLength }),
          v => (v && v.length <= this.referenceMaxLength) || this.$t('admin.sub.productOptions.form.input.reference.rule.maxLength', { count: this.referenceMaxLength })
        ],
        name: '',
        nameMinLength: 1,
        nameMaxLength: 70,
        nameRules: [
          v => !!v || this.$t('admin.sub.productOptions.form.input.name.rule.required'),
          v => (v && v.length >= this.nameMinLength) || this.$t('admin.sub.productOptions.form.input.name.rule.minLength', { count: this.nameMinLength }),
          v => (v && v.length <= this.nameMaxLength) || this.$t('admin.sub.productOptions.form.input.name.rule.maxLength', { count: this.nameMaxLength })
        ],
        description: '',
        descriptionMinLength: 0,
        descriptionMaxLength: 5000,
        descriptionRules: [
          v => !v || v.length >= this.descriptionMinLength || this.$t('admin.sub.productOptions.form.input.description.rule.minLength', { count: this.descriptionMinLength }),
          v => !v || v.length <= this.descriptionMaxLength || this.$t('admin.sub.productOptions.form.input.description.rule.maxLength', { count: this.descriptionMaxLength })
        ],
        taxRates: [],
        productTaxRates: [
          { name: '5,5%', rate: 0.055 },
          { name: '10%', rate: 0.1 },
          { name: '20%', rate: 0.2 }
        ],
        tags: [],
        productTags: [
          { id: 'cannot_serve_alone', name: this.$t('admin.sub.products.form.input.tags.values.cannot_serve_alone') },
          { id: 'vegetarian', name: this.$t('admin.sub.products.form.input.tags.values.vegetarian') },
          { id: 'vegan', name: this.$t('admin.sub.products.form.input.tags.values.vegan') },
          { id: 'casher', name: this.$t('admin.sub.products.form.input.tags.values.casher') },
          { id: 'halal', name: this.$t('admin.sub.products.form.input.tags.values.halal') },
          { id: 'gluten_free', name: this.$t('admin.sub.products.form.input.tags.values.gluten_free') },
          { id: 'organic', name: this.$t('admin.sub.products.form.input.tags.values.organic') },
          { id: 'alcohol', name: this.$t('admin.sub.products.form.input.tags.values.alcohol') },
          { id: 'hot', name: this.$t('admin.sub.products.form.input.tags.values.hot') },
          { id: 'cold', name: this.$t('admin.sub.products.form.input.tags.values.cold') }
        ],
        prices: [],
        images: [],
        defaultQuantity: 0,
        minQuantity: 0,
        maxQuantity: 1,
        valid: true,
        loading: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      }
    },
    watch: {
      productOption: async function () {
        if (this.productOption?.id === undefined) {
          this.group = null;
          this.reference = '';
          this.name = '';
          this.description = '';
          this.taxRates = [];
          this.tags = [];
          this.prices = [];
          this.images = [];
          this.defaultQuantity = 0;
          this.minQuantity = 0;
          this.maxQuantity = 1;
        } else {
          this.group = this.productOption?.group;
          this.reference = this.productOption?.reference;
          this.name = this.productOption?.name;
          this.description = this.productOption?.description;
          this.taxRates = this.productOption?.taxRates;
          this.tags = this.productOption?.tags === undefined ? [] : this.productOption?.tags;
          this.prices = this.productOption?.prices;
          this.images = this.productOption?.images;
          this.defaultQuantity = this.productOption?.defaultQuantity;
          this.minQuantity = this.productOption?.minQuantity;
          this.maxQuantity = this.productOption?.maxQuantity;
        }
      }
    },
    mounted: async function () {
      await this.refreshGroups();
    },
    methods: {
      async submitProduct () {
        this.loading = true;
        try {
          if (this.productOption?.id === undefined) {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'product/option', {
              groupId: this.group,
              reference: this.reference,
              name: this.name,
              description: this.description === null ? '' : this.description,
              taxRates: this.taxRates,
              tags: this.tags,
              prices: this.prices,
              images: this.images,
              defaultQuantity: this.defaultQuantity,
              minQuantity: this.minQuantity,
              maxQuantity: this.maxQuantity
            });
          } else {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'product/option', {
              id: this.productOption?.id,
              groupId: this.group,
              reference: this.reference,
              name: this.name,
              description: this.description === null ? '' : this.description,
              taxRates: this.taxRates,
              tags: this.tags,
              prices: this.prices,
              images: this.images,
              defaultQuantity: this.defaultQuantity,
              minQuantity: this.minQuantity,
              maxQuantity: this.maxQuantity
            });
          }
          this.$emit('success');
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('admin.sub.productOptions.form.error.fail'));
        }
        this.loading = false;
      },
      async refreshGroups () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'product/groups');
          if (response.data !== undefined && response.data.groups !== undefined) {
            this.productGroups = response.data.groups;
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
      },
      validate () {
        return this.$refs.form.validate();
      },
      updatePrices (prices) {
        this.prices = prices;
      },
      updateImages (images) {
        this.images = images;
      }
    }
  };
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"products","fluid":"","tag":"section"}},[_c('alert-notification',{attrs:{"message":_vm.alert}}),_c('edit-product-option-form',{attrs:{"product-option":_vm.editedProductOption},on:{"success":_vm.refreshData,"fail":_vm.fail},model:{value:(_vm.editProductOptionForm),callback:function ($$v) {_vm.editProductOptionForm=$$v},expression:"editProductOptionForm"}}),(_vm.$can('create', 'Admin.Meta.Product'))?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.editProductOptionForm,"loading":_vm.editProductOptionForm},on:{"click":function($event){$event.stopPropagation();_vm.editedProductOption={},_vm.editProductOptionForm=true}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v(" mdi-food ")]),_vm._v(" "+_vm._s(_vm.$t('admin.sub.productOptions.button.new'))+" ")],1)],1):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.sub.productOptions.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('admin.sub.productOptions.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","medium":"","dark":"","color":"black","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.refreshData($event)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"options":_vm.table.options,"items":_vm.productOptions,"search":_vm.table.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatGroup(item.group)))])]}},{key:"item.taxRates",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTaxRates(item.taxRates)))])]}},{key:"item.buyPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatBuyPriceTaxExcluded(item.prices))),_c('br'),_vm._v(_vm._s(_vm.formatBuyPriceTaxIncluded(item.taxRates, item.prices)))])]}},{key:"item.sellPriceShopify",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatSellPriceTaxExcluded(item.prices, 'shopify'))),_c('br'),_vm._v(_vm._s(_vm.formatSellPriceTaxIncluded(item.taxRates, item.prices, 'shopify')))])]}},{key:"item.sellPriceUberEats",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatSellPriceTaxExcluded(item.prices, 'ubereats'))),_c('br'),_vm._v(_vm._s(_vm.formatSellPriceTaxIncluded(item.taxRates, item.prices, 'ubereats')))])]}},{key:"item.sellPriceDeliveroo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatSellPriceTaxExcluded(item.prices, 'deliveroo'))),_c('br'),_vm._v(_vm._s(_vm.formatSellPriceTaxIncluded(item.taxRates, item.prices, 'deliveroo')))])]}},{key:"item.sellPriceJustEat",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatSellPriceTaxExcluded(item.prices, 'justeat'))),_c('br'),_vm._v(_vm._s(_vm.formatSellPriceTaxIncluded(item.taxRates, item.prices, 'justeat')))])]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.images.length))])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tags.length))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('update', 'Admin.Meta.Product'))?_c('span',[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.cloneProductOption(item)}}},[_vm._v(" mdi-file-multiple-outline ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"medium":""},on:{"click":function($event){_vm.editedProductOption=item,_vm.editProductOptionForm=true}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"medium":""},on:{"click":function($event){return _vm.archiveProductOption(item)}}},[_vm._v(" mdi-archive ")])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }